import React from "react";
import HeroSection from "../HeroSection";
import ProjectSection from "../ProjectSection";
import AboutSection from "../AboutSection";


const Home = () => {
  return (
    <div>
      <HeroSection />
      <ProjectSection />
      <AboutSection />
    
    </div>
  );
};

export default Home;
