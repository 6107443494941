import React from "react";
import Avatar from "../assets/Avatar";
import Button from "../assets/Button";

export default function EachProject(props) {
  return (
    <div>
      <div className={props.containerClassName}>
        <div className={props.avatarclassName}>
          <Avatar imgSrc={props.projectPicUrl} imgText="" />
        </div>

        <div className="project-text-div">
          <h3>{props.appName}</h3>
          <p>{props.appDescription}</p>

          <Button
            href={props.caseStudyLink}
            className="link-button link-display-button"
            text="CASE STUDY"
          />
          <Button
            href={props.viewLiveLink}
            target="_blank"
            className="link-button link-display-button"
            text="VIEW LIVE"
          />
        </div>
      </div>
    </div>
  );
}
