import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

import Header from "./Header";
import Home from "./Routes/Home";
import Footer from "./Footer";
import Contact from "./Contact";
import LogInPage from "./Routes/LogInPage";
import Profile from "./Routes/Profile";
import CaseStudy from "./Routes/CaseStudy";
import EditProjectPage from "./Routes/EditProjectPage";

library.add(fas, fab, far);

const App = () => {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />

          <Route
            path="/log-in"
            element={
              <div>
                <LogInPage />
                {/* <Register /> */}
              </div>
            }
          />

          <Route
            path="/profile"
            element={
              <div>
                <Profile />
              </div>
            }
          />

          <Route
            path="/project/edit/:id"
            element={
              <div>
                <EditProjectPage />
              </div>
            }
          />

          <Route
            path="/case-study/:id"
            element={
              <div>
                <CaseStudy />
              </div>
            }
          />
        </Routes>
        <Contact />
        <Footer />
      </div>
    </Router>
  );
};

export default App;
