import React, { useState, useEffect } from "react";
import IconLinks from "./assets/IconsLinks";

const date = new Date();
const year = date.getFullYear();

export default function Footer() {
  const [iconSize, setIconSize] = useState(true);

  const resizeIcon = () => {
    if (window.innerWidth >= 700) {
      setIconSize(false);
    } else {
      setIconSize(true);
    }
  };
  useEffect(() => {
    resizeIcon();
  }, []);

  window.addEventListener("resize", resizeIcon);

  return (
    <div>
      <footer>
        <p>Micheal Shippuden &copy; {year}</p>

        <div className="icon-buttons-container">
          <IconLinks
            icon="fa-brands fa-twitter"
            size={iconSize ? "1x" : "2x"}
            href="https://twitter.com/gotze_kiezmen7"
            className="icon-buttons twitter"
          />
          <IconLinks
            icon="fa-brands fa-linkedin"
            size={iconSize ? "1x" : "2x"}
            href="https://www.linkedin.com/in/fadaini-micheal-1b9181178/"
            className="icon-buttons linkedin"
          />
          <IconLinks
            icon="fa-brands fa-github"
            size={iconSize ? "1x" : "2x"}
            href="https://github.com/MichealKiezmen"
            className="icon-buttons github"
          />
        </div>
      </footer>
      
    </div>
  );
}
