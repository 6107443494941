import React from "react";
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';

 function Avatar (props ) {
  return  <LazyLoadImage
    alt={props.imgText}
    effect="blur"
    src={props.imgSrc} />;
}

export default trackWindowScroll(Avatar);