import React from "react";

function Input(props) {
  return (
    <div>
      <label htmlFor="" className="form-label">
        {props.label}
      </label>
      <input
        type={props.type}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        className="form-text-input"
        data-aos={props.animate}
        data-aos-duration={props.duration}
      />
    </div>
  );
}

function TextArea(props) {
  return (
    <div>
      <label htmlFor="" className="form-label">
        {props.label}
      </label>
      <textarea
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        className="form-text-input"
        cols={props.cols}
        rows={props.rows}
        data-aos={props.animate}
        data-aos-duration={props.duration}
      ></textarea>
    </div>
  );
}

export { Input, TextArea };
