import React from "react";

export default function Button(props) {
  return (
    <a href={props.href} target={props.target} onClick={props.onClick} className={props.className} type={props.type} data-aos={props.animate}
     data-aos-duration={props.duration}>
      {props.text}
    </a>
  );
}
