import React, { useState, useEffect } from "react";
import { Input } from "../assets/Input";
// import axios from "axios";
import axiosConfig from "../assets/axiosConfig";
import { useNavigate } from "react-router-dom";
import TinyMCE from "../assets/TinyMCE";

export default function Profile() {
  const [currentUser, setCurrentUser] = useState();
  useEffect(() => {
    axiosConfig
      .get("/all-projects")
      .then(function (res) {
        setCurrentUser(res.data.user);
      })
      .catch(function (err) {
        console.log("Server down");
      });
  }, []);

  const [projectInput, setProjectInput] = useState({
    appName: "",
    appDescription: "",
    liveLink: "",
  });
  const [file, setFile] = useState("");

  const [caseStudy, setCaseStudy] = useState("");

  const handleTextAreaChange = (caseStudyText) => {
    setCaseStudy(caseStudyText);
  };

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setProjectInput((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleFile = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (currentUser) {
      setProjectInput({
        appName: "",
        appDescription: "",
        liveLink: "",
      });
      setCaseStudy("");

      const data = new FormData();
      data.append("appPic", file);
      data.append("appName", projectInput.appName);
      data.append("appDescription", projectInput.appDescription);
      data.append("liveLink", projectInput.liveLink);
      data.append("caseStudy", caseStudy);

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
        withCredentials: true,
      };

      axiosConfig
        .post("/add-project", data, config)
        .then(function (response) {
          alert("New Project added succesfully!");
          navigate("/#projects");
        })
        .catch(function (error) {
          alert("Project not added, due to some errors!");
        });
    } else {
      alert("F*ck off!");
    }
  };

  let profileComponent = "";
  if (currentUser) {
    profileComponent = (
      <div className="log-in-container">
        <h3>Add a New Project</h3>
        <form action="/add-project" method="post" encType="multipart/form-data">
          <Input
            type="text"
            name="appName"
            value={projectInput.appName}
            onChange={handleChange}
            label="APP NAME"
          />

          <Input
            type="text"
            name="liveLink"
            value={projectInput.liveLink}
            onChange={handleChange}
            label="APP LINK"
          />

          <Input
            type="text"
            name="appDescription"
            value={projectInput.appDescription}
            onChange={handleChange}
            label="APP DESC."
          />
          <Input type="file" onChange={handleFile} label="APP IMAGE" />

          <TinyMCE value={caseStudy} onEditorChange={handleTextAreaChange} />

          <button
            onClick={handleSubmit}
            className="link-button  align-submit"
            type="submit"
          >
            Add Project
          </button>
        </form>
      </div>
    );
  }

  return profileComponent;
}
