import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "./assets/Avatar";
import Button from "./assets/Button";

export default function HeroSection() {
  const [iconDisplay, setIconDisplay] = useState(true);

  const showIcon = () => {
    if (window.innerWidth >= 700) {
      setIconDisplay(false);
    } else {
      setIconDisplay(true);
    }
  };

  useEffect(() => {
    showIcon();
    AOS.init();
  }, []);

  window.addEventListener("resize", showIcon);

  return (
    <div>
      <main>
        <div
          data-aos="fade-right"
          data-aos-easing="linear"
          data-aos-duration="2500"
        >
          <div className="project-image">
            <Avatar imgSrc="../../img/img1.jpg" imgText="Fadaini Micheal" />
          </div>
        </div>

        <div
          className="intro-text"
          data-aos="fade-left"
          data-aos-easing="linear"
          data-aos-duration="2500"
        >
          <h2> Hello, I am Micheal Oluwaseun Fadaini.</h2>
          <p>
            {" "}
            <i>
              {" "}
              Driven by a passion for sustainable solutions,
               I utilize the transformative power of technology
                to address environmental challenges. With adaptability, 
                collaboration, and precise coding skills, 
                I am committed to creating a positive impact and shaping a sustainable future.
            </i>
          </p>

          <Button
            className="link-button link-display-button"
            href="/#contact"
            text="GET IN TOUCH"
            animate="fade-up"
            duration="2500"
          />
        </div>
      </main>

      <div className="skill-list-div">
        <div className="skill-list">
          <div>
            <FontAwesomeIcon
              icon="fa-brands fa-html5 "
              size={iconDisplay ? "3x" : "5x"}
              className="skill-icon html"
            />
            <p>HTML</p>
          </div>

          <div>
            <FontAwesomeIcon
              icon="fa-brands fa-css3 "
              size={iconDisplay ? "3x" : "5x"}
              className="skill-icon css"
            />
            <p>CSS</p>
          </div>

          <div>
            <FontAwesomeIcon
              icon="fa-brands fa-js "
              size={iconDisplay ? "3x" : "5x"}
              className="skill-icon js"
            />
            <p>JAVASCRIPT</p>
          </div>

          <div>
            <FontAwesomeIcon
              icon="fa-brands fa-react "
              size={iconDisplay ? "3x" : "5x"}
              className="skill-icon react"
            />
            <p>REACT</p>
          </div>

          <div>
            <FontAwesomeIcon
              icon="fa-brands fa-bootstrap "
              size={iconDisplay ? "3x" : "5x"}
              className="skill-icon bootstrap"
            />
            <p>BOOTSTRAP</p>
          </div>

          <div>
            <FontAwesomeIcon
              icon="fa-brands fa-node "
              size={iconDisplay ? "3x" : "5x"}
              className="skill-icon node-js "
            />
            <p>NODE JS</p>
          </div>
        </div>
      </div>
    </div>
  );
}
