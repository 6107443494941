import React, { useEffect } from "react";
import Avatar from "./assets/Avatar";
import AOS from "aos";
import "aos/dist/aos.css";

export default function AboutSection() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div
      className="about-section"
      id="about"
      data-aos="flip-left"
      data-aos-duration="3000"
    >
      <div className="about-section-container">
        <h3>Who am I?</h3>
        <p>
        Driven by a passion for solving environmental problems using code, 
        I believe in technology's transformative potential to positively impact our planet. 
        As a versatile learner, I excel in multiple fields, adapt quickly, and embrace cutting-edge 
         to tackle complex environmental challenges. 
        </p>
        <p>
        Thriving in collaborative environments, I value diverse perspectives and 
        foster trust through strong relationships and open communication. 
        With research acumen and coding skills, I develop precise solutions for environmental issues. 
        Beyond my problem-solving dedication, I also possess discipline and teamwork skills from my 
         experience as a football player and sound engineer. 
        </p>
        <p>
          When I'm not writing codes, or researching , I play
          football(soccer), video games and see lots of movies.
        </p>
      </div>

      <div className="project-image">
        <Avatar imgSrc="../../img/mike.jpeg" imgText="Fadaini Micheal" />
      </div>
    </div>
  );
}
