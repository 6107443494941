import React, { useState, useEffect } from "react";
// import axios from "axios";
import { useParams } from "react-router-dom";
import Button from "../assets/Button";
import { useNavigate } from "react-router-dom";
import axiosConfig from "../assets/axiosConfig";

const CaseStudy = () => {
  let params = useParams();
  const navigate = useNavigate();
  const [eachProject, setEachProject] = useState([]);
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    axiosConfig
      .get("/case-study/" + params.id)
      .then(function (res) {
        setEachProject(res.data.foundProject);
        setCurrentUser(res.data.user);
      })
      .catch(function (err) {
        alert("Server down");
      });
  }, [params.id]);

  const [deleteAction] = useState({
    id: params.id,
    action: "delete",
  });

  const [editAction] = useState({
    id: params.id,
    action: "edit",
  });

  const handleEdit = (e) => {
    e.preventDefault();
    let actionValue = e.target.value;

    if (actionValue === "Edit") {
      axiosConfig
        .post("/edit-or-delete", editAction, {withCredentials:true})
        .then((response) => {
          navigate("/project/edit/" + params.id);
        })
        .catch((err) => {
          alert("Server down");
        });
    } else if (actionValue === "Delete") {
      axiosConfig
        .post("/edit-or-delete", deleteAction, {withCredentials:true})
        .then((response) => {
          alert("Project deleted successfully!");
          navigate("/");
        })
        .catch((err) => {
          alert("Server down");
        });
    }
  };

  return (
    <div>
      <div className="case-study-div">
        <div className="case-study-header">
          <h3>{eachProject.appName}</h3>
          <p>
            <i>{eachProject.appDescription}</i>
          </p>
          <div
            className="case-study-main-text"
            dangerouslySetInnerHTML={{ __html: eachProject.caseStudy }}
          />

          <Button
            href={eachProject.liveLink}
            className="link-button link-display-button "
            text="VIEW LIVE"
          />
        </div>

       
      </div>
      {currentUser && (
          <form action="/edit-or-delete" method="post">
            <input type="hidden" name="projectId" value={params.id} />
            <input
              type="submit"
              onClick={handleEdit}
              name="action"
              value="Edit"
              className="link-button link-display-button "
            />
            <input
              type="submit"
              name="action"
              onClick={handleEdit}
              value="Delete"
              className="button-delete link-display-button "
            />
          </form>
        )}
    </div>
  );
};

export default CaseStudy;
