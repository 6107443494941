import React, { useState, useEffect } from "react";
import MenuLinks from "./assets/MenuLinks";
import Avatar from "./assets/Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Header = () => {
  const [menu, setMenu] = useState(true);

  const handleClick = () => {
    setMenu(!menu);
  };

  const closeMenu = () => {
    showNavMenu();
  };

  const showNavMenu = () => {
    if (window.innerWidth >= 700) {
      setMenu(false);
    } else {
      setMenu(true);
    }
  };

  useEffect(() => {
    showNavMenu();
  }, []);

  window.addEventListener("resize", showNavMenu);

  return (
    <header>
      <div className="app-name-div">
        <h2>
          {" "}
          <a href="/" className="app-name">
            <Avatar imgSrc="../../img/default.png" imgText="HeadLogo" />
          </a>
        </h2>
      </div>

      <div className="menu-bar">
        <FontAwesomeIcon
          className="toggle-icon"
          icon="fa-solid fa-bars"
          onClick={handleClick}
        />
      </div>

      <div className={menu ? "nav-links-hide" : "nav-links-div"}>
        <FontAwesomeIcon
          icon="fa-solid fa-xmark"
          className="cancel-icon"
          onClick={closeMenu}
        />
        <MenuLinks className="nav-links" onClick={closeMenu} />
      </div>
    </header>
  );
};

export default Header;
