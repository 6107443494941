import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function IconLinks(props) {
  return (
    <a href={props.href} className={props.className} data-aos={props.animate}
        data-aos-duration={props.duration}>
      {" "}
      <FontAwesomeIcon icon={props.icon} size={props.size} />
    </a>
  );
}
