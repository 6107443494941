import React, { useState, useEffect } from "react";
import IconLinks from "./assets/IconsLinks";
import Form from "./sub-sections/Form";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Contact() {
  const [iconSize, setIconSize] = useState(true);

  const resizeIcon = () => {
    if (window.innerWidth >= 700) {
      setIconSize(false);
    } else {
      setIconSize(true);
    }
  };
  useEffect(() => {
    AOS.init();
    resizeIcon();
  }, []);

  window.addEventListener("resize", resizeIcon);
  return (
    <div className="contact-section" id="contact">
      <div className="get-in-touch">
        <h3>Get In Touch</h3>
        <p>
          Contact me through LinkedIn or Twitter if you would like to discuss
          anything. I look forward to hearing from you. To hire my services,
          please complete the form below with as many details as possible.
        </p>

        <div className="icon-buttons-container">
          <IconLinks
            icon="fa-brands fa-twitter"
            size={iconSize ? "2x" : "3x"}
            href="https://twitter.com/gotze_kiezmen7"
            className="icon-buttons twitter"
            duration="3000"
            animate="fade-right"
          />
          <IconLinks
            icon="fa-brands fa-linkedin"
            size={iconSize ? "2x" : "3x"}
            href="https://www.linkedin.com/in/fadaini-micheal-1b9181178/"
            className="icon-buttons linkedin"
            duration="3000"
            animate="fade-down"
          />
          <IconLinks
            icon="fa-brands fa-github"
            size={iconSize ? "2x" : "3x"}
            href="https://github.com/MichealKiezmen"
            className="icon-buttons github"
            duration="3000"
            animate="fade-left"
          />
        </div>
      </div>

      <div className="contact-form">
        <Form />
      </div>
    </div>
  );
}
