import React, { useState, useEffect } from "react";
import { Input } from "../assets/Input";
// import axios from "axios";
import axiosConfig from "../assets/axiosConfig";
import { useNavigate } from "react-router-dom";
import TinyMCE from "../assets/TinyMCE";
import { useParams } from "react-router-dom";


export default function EditProjectPage() {
  let params = useParams();

  const [projectInput, setProjectInput] = useState({
    appName: "",
    appDescription: "",
    liveLink: "",
  });
  const [file, setFile] = useState("");

  const [caseStudy, setCaseStudy] = useState("");

  const handleTextAreaChange = (caseStudyText) => {
    setCaseStudy(caseStudyText);
  };

  const navigate = useNavigate();

  useEffect(() => {
    axiosConfig
      .get("/edit-or-delete/" + params.id)
      .then(function (res) {
        setProjectInput(res.data);
        setCaseStudy(res.data.caseStudy);
      })
      .catch(function (err) {
        alert("Server down");
      });
  }, [params.id]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setProjectInput((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleFile = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setProjectInput({
      appName: "",
      appDescription: "",
      liveLink: "",
    });
    setCaseStudy("");

    const data = new FormData();
    data.append("appPic", file);
    data.append("appName", projectInput.appName);
    data.append("appDescription", projectInput.appDescription);
    data.append("liveLink", projectInput.liveLink);
    data.append("caseStudy", caseStudy);
    data.append("id", projectInput._id);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      }, withCredentials:true
    };

    axiosConfig
      .post("/edit-project", data, config)
      .then(function (response) {
        navigate("/#projects");
      })
      .catch(function (error) {
        alert("Project not added, due to some errors!");
      });
  };

  return (
    <div className="log-in-container">
      <h3>Add a New Project</h3>
      <form action="/add-project" method="post" encType="multipart/form-data">
        <Input
          type="text"
          name="appName"
          value={projectInput.appName}
          onChange={handleChange}
          label="APP NAME"
        />

        <Input
          type="text"
          name="liveLink"
          value={projectInput.liveLink}
          onChange={handleChange}
          label="APP LINK"
        />

        <Input
          type="text"
          name="appDescription"
          value={projectInput.appDescription}
          onChange={handleChange}
          label="APP DESC."
        />
        <Input type="file" onChange={handleFile} label="APP IMAGE" />

        <TinyMCE value={caseStudy} onEditorChange={handleTextAreaChange} />

        <button
          onClick={handleSubmit}
          className="link-button  align-submit"
          type="submit"
        >
          Add Project
        </button>
      </form>
    </div>
  );
}
