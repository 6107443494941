import React from "react";
import { Editor } from "@tinymce/tinymce-react";

export default function TinyMCE(props) {
  return (
    <div className="form-text-input">
      <label htmlFor="" className="form-label">
        CASE STUDY
      </label>

      <Editor
        apiKey={process.env.REACT_APP_API_KEY_TINYMCE}
        value={props.value}
        onEditorChange={props.onEditorChange}
        init={{
          height: 500,
          menubar: true,
          inline: true,
          plugins: " link image",
          toolbar:
            "undo redo | formatselect | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
    </div>
  );
}
