import React, { useState, useEffect } from "react";
// import axios from "axios";
import { Input, TextArea } from "../assets/Input";
import AOS from "aos";
import "aos/dist/aos.css";
import axiosConfig from "../assets/axiosConfig";

export default function Form() {
  useEffect(() => {
    AOS.init();
  }, []);

  const [inputData, setInputData] = useState({
    fName: "",
    email: "",
    message: "",
  });

  const handleChange = (event) => {
    let { name, value } = event.target;

    setInputData((prevValue) => {
      return { ...prevValue, [name]: value };
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const { fName, email, message } = inputData;

    const formData = { fName, email, message };

    setInputData({
      fName: "",
      email: "",
      message: "",
    });
    await axiosConfig
      .post("/sendMail", formData,)
      .then(function (response) {
        alert("Message sent successfully, you will get a reply shortly.");
      })
      .catch(function (error) {
        alert("Message not sent.");
      });
  };

  return (
    <div>
      <form action="/sendMail" method="post">
        <Input
          type="text"
          onChange={handleChange}
          name="fName"
          value={inputData.fName}
          label="Name"
          duration="1500"
          animate="fade-up"
        />
        <Input
          type="email"
          onChange={handleChange}
          name="email"
          value={inputData.email}
          label="Email Address"
          duration="1500"
          animate="fade-up"
        />
        <TextArea
          onChange={handleChange}
          name="message"
          value={inputData.message}
          cols="23"
          rows="2"
          label="Message"
          duration="1500"
          animate="fade-up"
        />

        <button
          onClick={handleSubmit}
          className="link-button  align-submit"
          type="submit"
          data-aos="flip-up"
          data-aos-easing="linear"
          data-aos-duration="2300"
        >
          Send Message
        </button>
      </form>
    </div>
  );
}
