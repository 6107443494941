import React, { useState, useEffect } from "react";
import axiosConfig from "./axiosConfig";

const MenuLinks = (props) => {
  const [currentUser, setCurrentUser] = useState();
 

  useEffect(() => {
    axiosConfig
      .get("/all-projects")
      .then(function (res) {
        setCurrentUser(res.data.user);
      })
      .catch(function (err) {
        console.log("Server down");
      });
  }, []);

  const logOut = () => {
    axiosConfig
      .get("/logout")
      .then(function (res) {})
      .catch(function (res) {
        alert("Server is down, can't log out at this moment!");
      });
  };

  // console.log(currentUser);
  

  return (
    <ul className={props.className} onClick={props.onClick}>
      <li>
        {" "}
        <a href="/"> Home </a>
      </li>
      <li>
        {" "}
        <a href="/#projects"> Projects </a>
      </li>
      <li>
        {" "}
        <a href="/#contact"> Contact </a>
      </li>
      <li>
        {" "}
        <a href="/#about"> About </a>
      </li>

      {currentUser && (
        <li>
          {" "}
          <a href="/profile"> Profile </a>
        </li>
      )}

      {currentUser && 
        <li>
          {" "}
          <a href="/" onClick={logOut}>
            {" "}
            Log-out{" "}
          </a>
        </li>
      
         /* (
          <li>
            {" "}
            <a href="/log-in"> Log-in </a>
          </li>
        ) */
      }
    </ul>
  );
};

export default MenuLinks;
