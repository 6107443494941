import React, { useState } from "react";
import { Input } from "../assets/Input";
// import axios from "axios";
import { useNavigate } from "react-router-dom";
import axiosConfig from "../assets/axiosConfig";

export default function LogIn() {
  const [adminInput, setAdminInput] = useState({
    username: "",
    password: "",
  });

  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdminInput((prevEntry) => {
      return {
        ...prevEntry,
        [name]: value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { username, password } = adminInput;
    const adminData = {
      username,
      password,
    };

    axiosConfig
      .post("/logIn", adminData, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then(function (response) {
        alert("Log in successful!");
        navigate("/");
      })
      .catch(function (error) {
        setErrorMessage("Invalid password or username!");
        console.log(error)
      });

    setAdminInput({
      username: "",
      password: "",
    });
  };
  return (
    <div className="log-in-container">
      <h3>Log In</h3>
      <form action="/logIn" method="post">
        <Input
          type="email"
          onChange={handleChange}
          name="username"
          value={adminInput.username}
          label="USERNAME"
        />
        <Input
          type="password"
          onChange={handleChange}
          name="password"
          value={adminInput.password}
          label="PASSWORD"
        />

        <p className="error-text">{errorMessage}</p>
        <button
          onClick={handleSubmit}
          className="link-button align-submit"
          type="submit"
        >
          Log in
        </button>
      </form>
    </div>
  );
}

function Register() {
  const [adminInput, setAdminInput] = useState({
    username: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdminInput((prevEntry) => {
      return {
        ...prevEntry,
        [name]: value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { username, password } = adminInput;
    const adminData = {
      username,
      password,
    };

    axiosConfig
      .post("/register", adminData, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
  })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

    setAdminInput({
      username: "",
      password: "",
    });
  };
  return (
    <div className="log-in-container">
      <h3>Register</h3>
      <form action="/register" method="post">
        <Input
          type="email"
          onChange={handleChange}
          name="username"
          value={adminInput.username}
          label="USERNAME"
        />
        <Input
          type="password"
          onChange={handleChange}
          name="password"
          value={adminInput.password}
          label="PASSWORD"
        />

        <button
          onClick={handleSubmit}
          className="link-button  align-submit"
          type="submit"
        >
          Register
        </button>
      </form>
    </div>
  );
}

export { Register };
