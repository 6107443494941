import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import EachProject from "./sub-sections/EachProject";
import AOS from "aos";
import "aos/dist/aos.css";
// import axios from "axios";
import axiosConfig from "./assets/axiosConfig";

export default function ProjectSection() {
  const [eachProject, setEachProject] = useState([]);
  // const [pageNumber, setPageNumber] = useState();
  // const [documentSize, setDocumentSize] = useState();

  useEffect(() => {
    AOS.init();
    axiosConfig
      .get("/all-projects")
      .then(function (response) {
        setEachProject(response.data.found);
        // setPageNumber(response.data.page);
        // setDocumentSize(response.data.documentSize);
      })
      .catch(function (error) {
        console.log("Server down");
      });
  }, []);



  return (
    <div
      className="project-section"
      id="projects"
      data-aos="fade-up"
      data-aos-duration="2500"
      data-aos-easing="ease-in"
    >


      <h3>Projects</h3>

    {eachProject === undefined || eachProject.length === 0 ? <ReactLoading type="spin" className="load-animation" /> : <div>
    {eachProject
        .slice()
        .reverse()
        .map((project, index) => (
          <EachProject
            key={project._id}
            containerClassName="each-project-div"
            avatarclassName={
              index % 2 === 0 ? "project-image" : "project-image-even-index"
            }
            appName={project.appName}
            projectPicUrl={project.appPic}
            appDescription={project.appDescription}
            caseStudyLink={"/case-study/" + project._id}
            viewLiveLink={project.liveLink}
          />
        ))}
        
    </div> }
      

     
      {/* 
      {eachProject.length > 4 && (
        <div>
          <Button
            href={
              "/all-projects?page=" +
              parseInt(pageNumber) -
              1 +
              "&documentSize=" +
              parseInt(documentSize)
            }
            className="link-button link-display-button"
            text="prev"
          />

          <Button
            href={
              "/all-projects?page=" +
              (parseInt(pageNumber) + 1) +
              "&documentSize=" +
              parseInt(documentSize)
            }
            className="link-button link-display-button"
            text="next"
          />
        </div>
      )} */}
    </div>
  );
}
